<template>
  <div>
    <button
      @click="showConfirmationPrompt = true"
      variant="primary"
      class="btn btn-primary float-right float-xs-center ml-3"
    >
      Bestelling annuleren
      <font-awesome-icon
        v-if="loading"
        :icon="['fad', 'spinner']"
        class="fa-spin"
        far
      />
    </button>
    <modal-prompt
      :show="showConfirmationPrompt"
      :message="promptMessage"
      @hide="showConfirmationPrompt = false"
      @confirm="cancelReservation()"
      modalid="reservation-cancel-prompt"
    />
  </div>
</template>

<script>
import ModalPrompt from '@/components/global/modal-prompt.vue'
import cancelReservationOrder from '@/services/v2/shop/reservationOrders/cancelReservationOrder'
export default {
  components: {
    ModalPrompt
  },
  props: {
    reservationOrderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showConfirmationPrompt: false,
      loading: false
    }
  },
  computed: {
    promptMessage() {
      return `Weet je zeker dat je bestelling #${this.reservationOrderId} wilt annuleren?`
    }
  },
  methods: {
    async cancelReservation() {
      this.loading = true
      await cancelReservationOrder({
        ReservationOrderID: this.reservationOrderId,
        SendEmail: true
      })
      this.$router.push({
        name: 'account-reservations',
        query: {
          infoMessage: `#${this.reservationOrderId} is succesvol geannuleerd`
        }
      })
      this.loading = false
    }
  }
}
</script>

<style></style>
