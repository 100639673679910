import store from '@/state/store'
import axiosInstance from '@/services/axiosInstance'

export default async function cancelReservationOrder({
  CustomerID,
  ReservationOrderID,
  SendEmail = false
} = {}) {
  const result = await axiosInstance.post(
    `/api/v2/shop/reservationOrders/${ReservationOrderID}/cancel`,
    { SendEmail },
    {
      params: {
        CustomerID
      },
      headers: { authorization: store.getters.token }
    }
  )

  return result.data.Collection
}
